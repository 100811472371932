<template>
    <section class="home d-flex overflow-hidden">
        <div class="container h-100">
            <div class="row h-100">
                <div class="position-relative z-index-1 col-md-4 col-xl-z5 h-100 pt-0 pt-md-5 order-1 order-md-0 d-flex justify-content-center">
                    <picture class="h-100 pt-0 pt-md-5">
                        <img class="model"
                             alt="Model"
                             src="../../../assets/campaign/mono-sculpting/model.png"
                             data-aos="fade-right"
                             data-aos-duration="1000">
                    </picture>
                </div>
                <div class="position-relative col-md-8 col-xl-7 order-0 order-md-1 mt-5 mt-md-0 pt-5 pt-md-0 d-flex flex-column justify-content-center align-items-center">
                    <div class="position-absolute pattern w-75 h-auto">
                        <svg id="pattern-1"
                             v-bind:class="{ 'done': isPatternVivusDone }"
                             version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 123.4474869 208.0577087" xml:space="preserve">
                            <path fill="transparent" stroke="#D8E4F0" d="M36.2087593,0.0000036c2.6166306,1.675621,4.3972473,4.4918518,5.2074966,7.5043945
                                c0.8116302,3.0111389,0.8194962,6.3313446-0.3290634,9.313385c-0.2430725,0.7064972-0.5089722,1.4476929-0.5060883,2.0524902
                                c0.0136414,0.3092346,0.0962067,0.410553,0.1951523,0.4228363c0.2837067,0.0990295,0.6808701,0.1832733,1.0409164,0.2609863
                                l2.2712708,0.4505768l0.3068848,0.0722351c0.0970688,0.0211487,0.2815247,0.0847015,0.3741455,0.1403351
                                c0.2325897,0.1013794,0.4499741,0.2599182,0.6516571,0.4438477c0.399025,0.392807,0.5892563,0.9224243,0.6631546,1.3738403
                                c0.1465683,0.8906555,0.0037003,1.7657776,0.1144485,2.2551727c0.0080566,0.0667419,0.0312424,0.0687256,0.0342484,0.1062012
                                c0.0203018-0.0040436-0.015213-0.0048981,0.0327377,0.0254211c0.0551682,0.0522003,0.1212463,0.0844727,0.2288971,0.1114502
                                c0.2043228,0.0613098,0.5509262,0.0493622,0.9225311,0.0285797c0.38134-0.0174713,0.805481-0.0605011,1.3130112,0.0221558
                                c0.1232147,0.015564,0.2579193,0.0561523,0.3875656,0.0921783c0.4105377,0.1550446,0.5788116,0.4919586,0.6287613,0.6275177
                                c0.1325531,0.3391724,0.1439819,0.5458984,0.1758499,0.7675171l0.0999069,1.1656799
                                c0.0170059,0.1722717,0.0432434,0.3358002,0.0702057,0.4372711c0.0067215,0.0136261,0.0109482,0.0202332,0.0125809,0.0321045
                                c0,0-0.0206528-0.0555573-0.0461273-0.0688629c-0.026413-0.0375366-0.0750504-0.0868683-0.1259308-0.114563
                                c-0.0258484-0.0149078-0.0530853-0.0266113-0.0801849-0.0348511l0.0157852,0.0051422
                                c0.0227509,0.0084991,0.0475388,0.0167542,0.075676,0.0237274c0.2070541,0.0692139,0.6377411,0.0592499,1.170105,0.2314301
                                c0.2488098,0.0775909,0.5483475,0.2498322,0.7118454,0.3748322c0.1815643,0.1259918,0.3457489,0.2572937,0.5063019,0.3887634
                                c0.6353683,0.5246735,1.1824951,1.0766907,1.7314148,1.5200806c0.2695541,0.2173004,0.5471344,0.4099121,0.7664413,0.5046539
                                c0.195961,0.0845184,0.4334412,0.1101379,0.7072678,0.0825653c0.5557632-0.0560303,1.176712-0.3524475,1.8007584-0.7127228
                                c1.2459183-0.7162628,2.4216919-1.7141418,3.8286285-2.5640411c1.4054489-0.825882,3.1336174-1.5761719,5.1293068-1.0758209
                                c1.0236359,0.295105,1.825798,1.0634308,2.2833099,1.8869324c0.4758911,0.8280334,0.6837845,1.7733765,0.706604,2.6629028
                                c0.0312195,1.7915497-0.5215759,3.43927-1.3528519,4.91008c-0.4238586,0.7340698-0.9331512,1.4255981-1.553154,2.0417175
                                c-0.6102295,0.6231995-1.4058838,1.1409454-2.1333694,1.4979706c-1.5014458,0.7588959-3.1055107,1.1773834-4.6761894,1.4505768
                                c-3.1514664,0.5317383-6.2374115,0.5126648-9.0320816,1.3183289c-1.3956833,0.392868-2.6696091,0.9960938-3.8039322,1.8638153
                                c-1.1359329,0.8621063-2.1220703,1.941864-2.9847183,3.1226807c-0.865593,1.1931763-1.5659103,2.4745789-2.1041718,3.8262177
                                c-0.5082016,1.3674011-0.8424606,2.8041382-1.0299072,4.2715759c-0.3488312,2.9437561-0.1352463,5.9765625,0.3178711,8.9789238
                                c0.46138,2.9946136,1.1070633,6.0343323,1.8377304,8.9780579c0.7594528,2.9596558,1.7174683,5.8994751,2.4447098,8.9209442
                                c1.5074615,6.0785828,1.4011383,12.3534851,0.9934082,18.4826202c-0.0238571-3.0826416-0.0601501-6.1665344-0.3083038-9.2257996
                                c-0.2426682-3.0626984-0.7678146-6.0777588-1.6337051-9.0090179c-0.829422-2.943512-1.8783188-5.8380127-2.7595444-8.8194122
                                c-0.4506836-1.4927063-0.80896-3.0104218-1.1619415-4.5107727c-0.3544388-1.5071106-0.68573-3.0265045-0.9526978-4.5653839
                                c-0.5481033-3.067791-0.8572617-6.2400627-0.5183334-9.4307213c0.1779404-1.5912323,0.5187073-3.1778412,1.0630112-4.7091522
                                c0.5756912-1.5212402,1.3455887-2.9738922,2.2705078-4.2883148c0.9272614-1.3239899,2.0417023-2.5451813,3.3553314-3.5578003
                                c1.3087616-1.017807,2.8655243-1.7616425,4.4335785-2.2063141c3.1558151-0.908783,6.3171158-0.8922882,9.2337723-1.4102631
                                c1.4611664-0.2454834,2.8730927-0.6177216,4.1300964-1.2467041c0.6527672-0.3243713,1.1641808-0.6569672,1.640377-1.1307526
                                c0.475708-0.4671783,0.8933029-1.0228577,1.2417679-1.6203766c0.6910629-1.1921539,1.1389236-2.5816498,1.1145172-3.8838348
                                c0.0046997-1.2668457-0.666275-2.515274-1.6047516-2.7901611c-0.4840317-0.1595612-1.1581879-0.1170959-1.784153,0.0318604
                                c-0.6323013,0.1491852-1.2588501,0.4438629-1.8797913,0.8021698c-1.2475128,0.7193909-2.4519272,1.7113647-3.8894958,2.5180511
                                c-0.7122574,0.3877716-1.5049896,0.7978821-2.4958038,0.8965149c-0.4903793,0.0445862-1.0450211-0.0047607-1.5559235-0.2353973
                                c-0.4842834-0.2233582-0.8275299-0.5009613-1.1519623-0.7710419c-0.6347885-0.5439606-1.1602707-1.1035309-1.7032318-1.5723267
                                c-0.1344376-0.1159821-0.2688141-0.2288208-0.401619-0.3255005c-0.1426392-0.1085968-0.1825638-0.1275024-0.2982635-0.1756744
                                c-0.19767-0.0882874-0.5564804-0.0878906-1.0898743-0.2361298c-0.064682-0.0189972-0.130806-0.0423431-0.1976624-0.0693054
                                l-0.0516281-0.0205994c-0.0462799-0.0227966-0.0926971-0.049057-0.1377335-0.0785065
                                c-0.0885086-0.0574493-0.1778717-0.1337433-0.2393723-0.2033234c-0.0724716-0.0970154-0.1552124-0.230957-0.1895294-0.3188171
                                l-0.0815506-0.2409515c-0.0655518-0.2728424-0.0854034-0.4927826-0.1030502-0.703949l-0.0680618-1.1391296
                                c-0.0115662-0.159668-0.0453949-0.3408661-0.0565186-0.3604279c-0.0060501-0.0294495,0.0091476,0.02742,0.0378723,0.0511322
                                c-0.0055237,0.0008698,0.1690674,0.1637726,0.2294922,0.1494446c-0.0564041-0.0132904-0.1046295-0.0359192-0.1731262-0.0446014
                                c-0.2534561-0.0536957-0.6058807-0.0424042-0.9922714-0.0297699c-0.3937912,0.0032043-0.8329391,0.0437317-1.373642-0.1108246
                                c-0.2628708-0.0730743-0.5529327-0.2172852-0.7765884-0.4320984c-0.110817-0.084671-0.2338333-0.2634583-0.3286591-0.4210358
                                c-0.059433-0.1431427-0.1318588-0.3145752-0.1485443-0.4398499c-0.1015167-0.5391083-0.0526733-0.9366455-0.0428391-1.3218231
                                c0.0170441-0.378891,0.0269012-0.729599-0.0137177-1.0323944c-0.0807648-0.6153259-0.3399887-0.8957672-0.9879227-1.0447388
                                l-2.2462311-0.5276337c-0.3884506-0.1011353-0.7472839-0.180069-1.1867981-0.3480377
                                c-0.0757446-0.0267334-0.3226929-0.1362-0.4194336-0.2597809c-0.1241531-0.1175385-0.2194366-0.2488861-0.2805328-0.3938141
                                c-0.1369934-0.2860565-0.152565-0.553833-0.1564407-0.7974854c0.0498047-0.9463348,0.3747635-1.666214,0.6575165-2.3979797
                                c1.2145996-2.7324982,1.371048-5.8897247,0.7284927-8.839859C40.243412,4.6924324,38.6972008,1.854473,36.2087593,0.0000036z"></path>
                            <path fill="transparent" stroke="#D8E4F0" d="M58.3356514,66.26828c0.2990494,2.4625244,0.9835129,4.7596283,2.0336533,6.8520966
                                c1.0626831,2.0751343,2.4613266,3.971344,4.2191124,5.406189c1.735199,1.4579773,3.8512421,2.4443817,6.1073456,2.9574738
                                c2.2677689,0.5042877,4.6728058,0.5167847,7.1241684,0.1482239c-2.1944733,1.164978-4.7978363,1.6544342-7.3677216,1.3933563
                                c-2.5781326-0.2389069-5.102066-1.2907867-7.1388969-2.9597778c-2.0131302-1.6980896-3.510498-3.917099-4.3730927-6.3331909
                                C58.0675697,71.3277893,57.8014946,68.6996002,58.3356514,66.26828z"></path>
                            <path fill="transparent" stroke="#D8E4F0" d="M55.3112602,64.1324615c-0.4661484,6.7579956,0.8866501,13.3981476,1.1001282,20.1982574
                                c0.062912,3.3953705-0.168541,6.8493958-1.1098175,10.1860504c-0.4711609,1.6637421-1.1204071,3.2905273-1.9780273,4.81427
                                c-0.8552704,1.5266113-1.9230881,2.9217987-3.1006851,4.1943512c-2.3651428,2.5504608-5.146553,4.6124268-8.0237427,6.4425507
                                c-2.8803329,1.8356018-5.8689194,3.4467773-8.8220291,5.065033c-2.9530182,1.618927-5.8820114,3.2412872-8.6211166,5.1107635
                                c-2.7380829,1.8657074-5.3129883,3.9630585-7.4799118,6.4047852c-2.1879263,2.4197235-3.9406042,5.1953888-4.9711294,8.2763367
                                c-1.0607986,3.078125-1.5413208,6.374939-1.8695145,9.6951599c-0.3248863,3.3018799-0.4228172,6.6318359,0.4733963,9.7065125
                                c0.9103432,3.0474548,2.8490067,5.7555542,5.3546591,7.8202515c2.5049362,2.0752563,5.4919891,3.585907,8.663475,4.4931641
                                c3.1761551,0.9309998,6.502121,1.3652649,9.8441238,1.5916443c6.6996536,0.430481,13.470314,0.490509,20.2025909,0.8425293
                                c-3.3732376,0.0645447-6.746254,0.1115723-10.1189499,0.1340942c-3.3726044,0.0201721-6.7431793,0.1022339-10.1359482,0.0024109
                                c-3.3865738-0.1223755-6.8019104-0.4796753-10.1311951-1.3538818c-3.3323517-0.8574524-6.540741-2.3577271-9.2922583-4.5143433
                                c-1.3702087-1.0823975-2.6346703-2.3284607-3.6727104-3.7658997c-1.0398521-1.4335632-1.837822-3.0553894-2.3707619-4.7553406
                                c-1.0287056-3.4308167-0.9266319-6.9879761-0.6288414-10.3699341c0.3054657-3.3831177,0.7631683-6.8188477,1.8720741-10.1407471
                                c1.0826569-3.3368073,2.9752045-6.4060364,5.291687-8.9933929c4.7022619-5.1892242,10.7940435-8.6230621,16.7245255-11.781311
                                c5.9226074-3.1739349,11.9312134-6.3156433,16.6017914-10.9656525c2.3190765-2.3204956,4.0246277-5.1591492,4.9823074-8.3105469
                                c0.9848404-3.1413879,1.2946396-6.4887085,1.3413391-9.8239136C55.5575447,77.6581116,54.3932228,70.8576202,55.3112602,64.1324615z"></path>
                            <path fill="transparent" stroke="#D8E4F0" d="M109.9229889,55.427906c0.300766,3.7284241,1.8267517,7.1109314,2.9372711,10.6902733
                                c0.526535,1.7969513,0.9644318,3.6801758,0.9217529,5.6418152c-0.0233002,1.9555664-0.5693207,3.9311829-1.5388184,5.647995
                                c-0.9793854,1.715744-2.3114624,3.1775208-3.7624969,4.430069c-1.4506073,1.2615814-3.0180664,2.3365326-4.5953674,3.3508911
                                c-1.5750275,1.0167542-3.1951904,1.9399261-4.7359619,2.9184113c-1.5441284,0.9759369-3.0443268,2.0149841-4.4794159,3.1301422
                                c-2.8852081,2.2046356-5.5278015,4.7277832-7.642746,7.63591c-1.0630493,1.4473724-1.9886627,2.992218-2.7339478,4.6203156
                                c-0.7310181,1.6335449-1.3137512,3.3370209-1.7319946,5.0915985c-0.7775269,3.520752-0.9519806,7.2032318-0.5824432,10.8497009
                                c0.2913818,3.6635895,1.1655426,7.2713013,2.2245789,10.8286591c-1.4855652-3.4051208-2.5660706-7.0028839-3.1970978-10.704422
                                c-0.6119537-3.701828-0.6605682-7.5379639,0.0270233-11.292038c0.6894073-3.75354,2.2540131-7.367218,4.4539032-10.4960022
                                c2.2067871-3.1338806,4.9817963-5.7972107,7.9625702-8.1169128c2.9877319-2.3231964,6.3086853-4.1810455,9.4339447-6.0661774
                                c3.1189423-1.8854065,6.1297302-4.0224762,8.0203857-6.970108c0.9213562-1.4766846,1.4432068-3.1641846,1.5744019-4.9124146
                                c0.1476135-1.7550049-0.1618805-3.5578003-0.5610657-5.3401184
                                C111.1515198,62.8007393,109.7667236,59.1836281,109.9229889,55.427906z"></path>
                            <path fill="transparent" stroke="#D8E4F0" d="M78.5912933,15.5768318c0.4874573,3.2194366,1.6952515,6.2837067,3.407486,9.0008392
                                c1.7057495,2.7297058,4.015625,5.0440369,6.6096039,6.886261c2.586792,1.8567657,5.5283661,3.2260284,8.6273499,3.8517914
                                l1.160675,0.2291718c0.3896332,0.0563202,0.7835846,0.0819092,1.1745605,0.1248779
                                c0.780304,0.0957336,1.5695648,0.1259613,2.3672485,0.1193237c1.5941467-0.0044098,3.2100372-0.1188812,4.8579865-0.1617126
                                c3.2527466-0.1153259,6.8831024,0.1885834,9.8392029,2.265152c1.4700775,1.0075226,2.6304626,2.5686646,3.2170563,4.2264404
                                c0.6083374,1.6569366,0.8574219,3.3372192,1.0413055,4.9601288c0.4560089,3.2670898,0.5769653,6.5568237,0.6478882,9.8363495
                                c0.0107574,3.2790527-0.0900116,6.5518494-0.2767639,9.8099785c-0.4127197,6.5121918-1.1046448,12.9748688-1.3251648,19.4321594
                                c-0.1403961,3.2289124-0.1129456,6.4528961,0.0565948,9.6641846l0.1710968,2.4046021
                                c0.0628204,0.8008728,0.187561,1.5935974,0.2688904,2.3922424c0.1483002,1.5993042,0.421814,3.1822205,0.7044373,4.7727356
                                c0.5784302,3.1780548,1.2900696,6.3598938,1.8094025,9.596344c0.5172729,3.2401886,0.7978821,6.5105743,0.8976288,9.7778625
                                c0.115799,6.5405884-0.4148102,13.0514221-1.3360291,19.4889832c0.4708405-6.4841003,0.8017883-12.9874115,0.3565521-19.445694
                                c-0.2050018-3.2261963-0.5617828-6.4383545-1.1626282-9.6042633c-0.600296-3.169342-1.3975372-6.3156738-2.0744934-9.5166321
                                c-1.4088593-6.421814-1.7097168-13.0634308-1.5108032-19.5879059c0.16539-6.5435944,0.7927551-13.0282745,1.1504669-19.4952698
                                c0.2153473-3.2310753,0.3439484-6.4578667,0.3636017-9.680645c-0.0403137-3.2171936-0.1284332-6.4387512-0.5433807-9.6193085
                                c-0.1626129-1.6021423-0.3964691-3.1673126-0.9002838-4.571701c-0.4965515-1.40979-1.35672-2.5789337-2.5585785-3.4356689
                                c-1.1909485-0.862793-2.6414642-1.40242-4.1398315-1.7287903c-1.4991608-0.3334045-3.0752869-0.4054108-4.6780853-0.4099731
                                c-1.6024933-0.0039825-3.2346954,0.0662537-4.8918762,0.0250092c-0.8281097-0.016449-1.6635132-0.0711365-2.4948273-0.1953735
                                c-0.4151764-0.0570526-0.8329315-0.0974579-1.2449188-0.1690826l-1.2243652-0.276535
                                c-3.2601318-0.7603912-6.3057098-2.2737579-8.9290466-4.2947998C82.7897491,28.2234688,79.1286011,22.0968361,78.5912933,15.5768318
                                z"></path>
                            <path fill="transparent" stroke="#D8E4F0" d="M105.9354706,88.9377899c1.888092,3.8304138,2.8375397,8.1009369,3.3233643,12.3442841
                                c0.503418,4.2580719,0.5239258,8.5281677,0.574295,12.7530975c0.0131683,4.2272186,0.04039,8.4263,0.412323,12.5888672
                                c0.384491,4.1587067,1.1752777,8.2814484,2.7046051,12.2587128c-2.2846222-3.6107483-3.5711975-7.8300934-4.2525482-12.0596771
                                c-0.7028961-4.2460022-0.814743-8.5362549-0.8631439-12.7740936c-0.0102234-4.2424164,0.052948-8.4525452-0.1252136-12.6418457
                                C107.5174103,97.2195587,107.0402222,93.0506134,105.9354706,88.9377899z"></path>
                            <path fill="transparent" stroke="#D8E4F0" d="M26.5866356,133.7008972c9.2218857-0.8105774,18.4586792-1.4847565,27.7297211-1.7538452
                                c9.2675705-0.1984253,18.5793571,0.1057892,27.8069344,1.3723755c9.2225647,1.2467346,18.3373871,3.5977783,26.9486237,7.2543335
                                c2.1563873,0.9465637,4.2355499,1.8913879,6.3620911,3.0483704c0.5370483,0.3136902,1.0815582,0.6847229,1.5731812,1.1606445
                                c0.496933,0.4786987,0.8974457,1.0500488,1.2002411,1.6333618c0.6227417,1.1802368,0.8662262,2.3845825,1.104538,3.5954285
                                c0.36763,2.4412842,0.1727905,4.9454346-0.5141449,7.2943115c-0.7164154,2.3384094-1.8672791,4.5516357-3.4541016,6.4281311
                                c-1.5941772,1.8506165-3.6118469,3.4234314-5.8623047,4.3755493c-2.1298981,0.9424744-4.2696381,1.8625488-6.4370575,2.709137
                                c-4.3247986,1.7202148-8.7167511,3.2532043-13.141861,4.6630249c-8.8378296,2.8341064-17.8562622,5.1477661-26.7728996,7.4543152
                                c-8.8600311,2.4926453-17.4675064,5.9069519-25.6594009,10.142395c-8.2261887,4.1783752-15.9983826,9.2487793-23.2681274,14.9792786
                                c6.984024-6.0827942,14.6849442-11.3460388,22.8084869-15.8453064c8.1579056-4.4422607,16.7797089-8.0518799,25.6887283-10.753418
                                c4.479229-1.3238525,8.9331856-2.4597168,13.3864708-3.7184143c4.4485626-1.2451782,8.8743744-2.5586243,13.2575226-3.9910889
                                c4.3841705-1.4284668,8.7261353-2.9763489,12.9910126-4.7052917c2.1380615-0.8511963,4.2448578-1.7738342,6.3406677-2.7175293
                                c2.0171509-0.8676147,3.7221527-2.2393494,5.1446686-3.8841248c1.4152832-1.6495056,2.4545441-3.6197205,3.1074371-5.6955261
                                c0.6244812-2.0867615,0.8059387-4.3007507,0.5007782-6.4394836c-0.1997223-1.0544128-0.4252777-2.1464233-0.893631-3.0241394
                                c-0.4447937-0.8980408-1.1067352-1.5086365-2.0453949-2.0570068c-1.9322662-1.0667114-4.0613403-2.0605164-6.1363068-2.9843445
                                c-4.2057495-1.8142395-8.5477905-3.3219299-12.9749756-4.5345459c-4.4340515-1.1872253-8.9344635-2.1464233-13.4782562-2.8731079
                                c-9.0952225-1.4361572-18.336544-1.9091797-27.5724449-1.9126892
                                C45.0844002,132.8605347,35.8369865,133.3397217,26.5866356,133.7008972z"></path>
                            <path fill="transparent" stroke="#D8E4F0" d="M93.4681396,155.8196716c-8.2836304,0.2299805-16.3891296,2.6062012-23.3653336,6.7937927
                                c-6.9967537,4.2218628-12.8961906,10.0493774-19.4638252,15.1983948c-3.3024979,2.5595398-6.803833,4.9358521-10.6168213,6.7719727
                                c-3.8074722,1.8092346-7.9377136,3.1453247-12.2144928,3.3598022l-1.5996017,0.0369568l-1.5982666-0.0723877
                                c-1.0441132-0.0760193-2.0514755-0.1672058-3.053627-0.1882324c-0.9948502-0.0144348-1.9869156,0.0450439-2.8774109,0.3201294
                                c-0.4463654,0.135376-0.8450394,0.3471069-1.1998215,0.6080322c-0.34729,0.271698-0.6069031,0.627655-0.8245468,1.0202332
                                c-0.4088669,0.7941284-0.4156418,1.8303223-0.5599747,2.8862c-0.1577063,1.0624695-0.4572668,2.0982971-0.9149466,3.0561523
                                c-0.8839874,1.9302368-2.3592567,3.5570068-4.1418114,4.6151428c1.6607018-1.238678,2.9321632-2.937439,3.654171-4.8227844
                                c0.3687668-0.9405212,0.5766068-1.9345703,0.6514359-2.9268188c0.0761414-0.9862976-0.0357971-2.0904846,0.4173737-3.2109375
                                c0.2390213-0.5351257,0.5821905-1.039856,1.0330801-1.4352417c0.4549789-0.3809814,0.9712067-0.6795959,1.5072021-0.8744202
                                c1.0735016-0.3927002,2.1746063-0.50354,3.2423477-0.5464783c1.0681152-0.0403137,2.1267242,0.0236206,3.1483459,0.0577087
                                l1.5046997,0.0115967l1.5018692-0.0916748c3.9997101-0.3816223,7.8820877-1.6697693,11.488533-3.5024719
                                c3.6273422-1.8084412,7.0087204-4.1162109,10.2233124-6.6488342c6.4702225-5.0123596,12.5210419-10.877533,19.9162712-14.9689636
                                C76.6810684,157.1567688,85.1975403,155.2689514,93.4681396,155.8196716z"></path>
                            <path fill="transparent" stroke="#D8E4F0" d="M115.3940735,162.2628784c1.2386322,0.9372253,2.4321289,1.9451904,3.5164642,3.0798035
                                c1.0908051,1.1255188,2.0768433,2.3826294,2.8296967,3.8163147c0.7491608,1.4287415,1.1383209,3.1147156,0.998703,4.8063354
                                c-0.1569214,1.691803-0.9472504,3.3143311-2.0618439,4.5629578c-1.108139,1.2683716-2.5116577,2.2395325-4.0353088,2.8955688
                                c-1.5128784,0.6650391-3.1062622,1.0509033-4.6794891,1.3164063c-1.5796661,0.2662048-3.1371307,0.384491-4.6864777,0.5244751
                                c-1.5518036,0.1435852-3.1068573,0.2504883-4.6620941,0.3506775c-6.224411,0.3484192-12.4695587,0.3305054-18.6844635-0.0884705
                                c-6.2157135-0.3996887-12.3968735-1.2516785-18.478775-2.5177917c6.1932068,0.4964294,12.3721771,0.821991,18.5464935,0.9585876
                                c6.1745605,0.0988159,12.3463135,0.0314941,18.5023956-0.3478394c3.0634613-0.1809387,6.192688-0.3625488,9.1772766-0.7462463
                                c2.9641876-0.3812256,5.8513184-1.3245239,7.8545227-3.3960876c1.0024567-1.0133972,1.6754761-2.2856445,1.9139557-3.6799011
                                c0.2114563-1.3921814-0.0059814-2.8668518-0.5910645-4.2327881
                                C119.730896,166.7779846,117.5579376,164.4611206,115.3940735,162.2628784z"></path>
                            <path fill="transparent" stroke="#D8E4F0" d="M38.4874306,174.4096985c-1.246048,1.3924866-2.9670563,2.3754272-4.777092,2.9631042
                                c-1.8181915,0.5952759-3.717186,0.8623352-5.5917664,0.996521c-0.9380417,0.0703735-1.8739243,0.1137695-2.8037643,0.1278992
                                c-0.9254456,0.0191956-1.8746033,0.0398865-2.849823-0.0200806c-0.9707336-0.0490417-1.9857025-0.2494202-2.9204636-0.524292
                                c-0.9386673-0.2745972-1.8235474-0.6117249-2.6836243-0.9237366c-1.7176428-0.6573792-3.3293295-1.2036438-4.9417067-1.0227356
                                c-0.8022652,0.0698547-1.5836716,0.2868652-2.2181702,0.6805725c-0.6291885,0.4091187-1.0826073,0.9408264-1.334301,1.6851196
                                c-0.2682762,0.747406-0.3635979,1.7876892-0.8923492,2.7361755c-0.5161018,0.92099-1.268856,1.6619568-2.0992279,2.1879883
                                c-1.6874352,1.0379333-3.5421791,1.4640198-5.3751411,1.7500916c1.7128105-0.7051086,3.460453-1.4080505,4.7952766-2.5426331
                                c0.669136-0.5524292,1.1914597-1.2251892,1.4979362-1.9639282c0.3092117-0.7237244,0.3338013-1.5761719,0.5745964-2.602478
                                c0.2287102-1.0490723,0.9431152-2.0925598,1.8577271-2.7060547c0.9132462-0.6328125,1.9500198-0.9615479,2.9814568-1.1066895
                                c1.0269775-0.1372681,2.1017952-0.0524292,3.0825005,0.1730652c0.9734726,0.22229,1.8911123,0.5367126,2.769042,0.8583984
                                c1.7576523,0.6861877,3.3394775,1.297821,5.0676041,1.4396667c1.7355728,0.1786804,3.6348267,0.1641235,5.4410095,0.2197876
                                C31.6714897,176.8254089,35.3948479,176.5743713,38.4874306,174.4096985z"></path>
                        </svg>
                    </div>

                    <h1 class="position-relative d-none d-md-block display-4 text-center font-weight-bold mb-4 z-index-2"
                        data-aos="fade"
                        data-aos-duration="1000">
                        MonoSculpting<br>
                        超・頻消脂修身療程
                    </h1>

                    <h1 class="position-relative d-md-none text-center font-weight-bold mb-4 z-index-2"
                        data-aos="fade"
                        data-aos-duration="1000">
                        MonoSculpting<br>
                        超・頻消脂修身療程
                    </h1>

                    <h1 class="position-relative d-none d-md-block text-center font-weight-bold mb-4 z-index-2"
                        data-aos="fade"
                        data-aos-duration="1000">
                        <span>
                            有效凋亡40%脂肪
                        </span>
                        <a class="font-weight-bolder text-decoration-none"
                           style="font-size: 110%; "
                           href="#mono-sculpting-disclaimer">
                            *
                        </a>
                        <br>
                        專減鬼祟位
                    </h1>

                    <h2 class="position-relative d-md-none text-center font-weight-bold mb-4 z-index-2"
                        data-aos="fade"
                        data-aos-duration="1000">
                        <span class="mr-1">
                            有效凋亡40%脂肪
                        </span>
                        <a class="font-weight-bolder text-decoration-none"
                           style="font-size: 110%; "
                           href="#mono-sculpting-disclaimer">
                            *
                        </a>
                        專減鬼祟位
                    </h2>

                    <h2 class="position-relative d-none d-md-block text-center mb-4 z-index-2"
                        data-aos="fade"
                        data-aos-duration="1000">
                        首次體驗價<br>
                        $1,750 / 部位
                    </h2>

                    <h3 class="position-relative d-md-none text-center mb-4 z-index-2"
                        data-aos="fade"
                        data-aos-duration="1000">
                        首次體驗價<br>
                        $1,750 / 部位
                    </h3>

                    <h3 class="position-relative text-center z-index-2"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="1500">
                        <button class="btn btn-lg btn-light rounded-0"
                                v-on:click="scrollToForm">
                            立即預約
                        </button>
                    </h3>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Vivus from "vivus";

    export default {
        name: "MonoSculptingHome",
        data () {
            return {
                patternVivus: null,
                isPatternVivusDone: false,
            };
        },
        methods: {
            scrollToForm () {
                document.getElementById("enquiry").scrollIntoView({
                    "behavior": "smooth"
                });
            }
        },
        mounted () {
            this.patternVivus = new Vivus(
                "pattern-1",
                {
                    type: "delayed",
                    start: "manual",
                    duration: 150
                },
                () => {
                    this.isPatternVivusDone = true;
                }
            );
            this.patternVivus.play();
        }
    }
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import "~bootstrap/scss/mixins/_breakpoints";
    @import "./variables.scss";

    .home {
        height: auto !important;
        background-color: $background-color;
        color: $text-color;

        .model {
            width: 100%;

            @include media-breakpoint-up(sm) {
                height: 100vh;
                width: auto;
            }
        }

        svg {
            &#pattern-1 {
                path {
                    transition: fill 250ms;
                }

                &.done {
                    path {
                        fill: $background-color-alt;
                    }
                }
            }
        }
    }
</style>
